import img1 from '../images/home.png';
import img2 from '../images/building.png';
import img19 from '../images/icon1.svg';
import img11 from '../images/p2.svg';
import img12 from '../images/p3.svg';
import img13 from '../images/p4.svg';
import img14 from '../images/p5.svg';
import img15 from '../images/p6.svg';
import img16 from '../images/p7.svg';
import img17 from '../images/p8.svg';




const data = [
    {
      question: "Quels produits vous intéressent ?",
      options: [
        { text: "Panneaux photovoltaïques", image: img19  },
        { text: "Pompe à chaleur",  image: img11  },
        { text: "Chauffe-eau thermodynamique",  image: img12  },
        { text: "Isolation des murs par l'extérieur",  image: img13  },
        { text: "Isolation du plancher bas",  image: img14  },
        { text: "Isolation des combles",  image: img15  },
        { text: "Borne de recharge",  image: img16  },
        { text: "VMC",  image: img17  },
      ],
    },
  ];
  
  export default data;
  