import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './Quiz/Quiz.css';
import data from '../assets/data';
import { useSelector, useDispatch } from 'react-redux';
import { addUser } from '../UserReducer';
import image4 from '../images/call.svg';
import image5 from '../images/img1.svg';
import congratulations from '../images/congratulations.png';
import axios from 'axios';


const Quiz = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [index, setIndex] = useState(0);
    const [result, setResult] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [answerSelected, setAnswerSelected] = useState(false);

    const users = useSelector((state) => state.users);
    const dispatch = useDispatch();

    useEffect(() => {
        const storedAnswers = sessionStorage.getItem('selectedAnswers');
        if (storedAnswers) {
            // setSelectedAnswers(JSON.parse(storedAnswers));
            setSelectedAnswers({}); // Réinitialise les réponses sélectionnées
        }
    }, []);

    // const checkAnswer = (selectedOptionValue) => {
    //     setSelectedAnswer(selectedOptionValue);
    //     setSelectedAnswers(prevAnswers => {
    //         const updatedAnswers = { ...prevAnswers, [index]: selectedOptionValue };
    //         return updatedAnswers;
    //     });
    //     setAnswerSelected(true); // Mettre à jour l'état pour indiquer qu'une réponse a été sélectionnée
    // };

    const checkAnswer = (selectedOptionValue) => {
        setSelectedAnswers(prevAnswers => {
            const updatedAnswers = { ...prevAnswers };
    
            // S'assurer que `updatedAnswers[index]` est bien un tableau
            if (!Array.isArray(updatedAnswers[index])) {
                updatedAnswers[index] = []; // Initialisation correcte
            }
    
            // Vérifier si l'option est déjà sélectionnée
            if (updatedAnswers[index].includes(selectedOptionValue)) {
                // Si elle est déjà sélectionnée, on la supprime (toggle)
                updatedAnswers[index] = updatedAnswers[index].filter(option => option !== selectedOptionValue);
            } else {
                // Sinon, on l'ajoute
                updatedAnswers[index] = [...updatedAnswers[index], selectedOptionValue];
            }
    
            // 🔥 Afficher correctement la sélection dans la console
            console.log(`Choix sélectionnés pour la question ${index + 1}:`, updatedAnswers[index]);
    
            return updatedAnswers;
        });
    
        setAnswerSelected(true); // Marquer qu'une réponse a été sélectionnée
    };
    
          

    const handleClick = () => {
        // console.log('Storing in session:', selectedAnswers);
        sessionStorage.setItem('selectedAnswers', JSON.stringify(selectedAnswers));
    };

    const next = () => {
        handleClick();
        if (index === data.length - 1) {
            console.log('Quiz Completed:', selectedAnswers);
            setResult(true);
            return;
        }
        setIndex(index + 1);
        setSelectedAnswer(null);
        setAnswerSelected(false); // Réinitialiser l'état lorsque vous passez à la question suivante
    };

    const restartQuiz = () => {
        setIndex(0);
        setResult(false);
        setSelectedAnswer(null);
        setSelectedAnswers({});
        sessionStorage.removeItem('selectedAnswers');
    };

    const onSubmit = async (formData) => {
        // Combine les données du formulaire avec les réponses sélectionnées
        const combinedData = {
            ...formData,
            habitation: selectedAnswers[0], // Changez l'index en fonction de votre structure
            toiture: selectedAnswers[1],    // Changez l'index en fonction de votre structure
            chauffage: selectedAnswers[2],
            sous_sole: selectedAnswers[3],
            // Changez l'index en fonction de votre structure
            ...selectedAnswers
        };

        // Stocke les données combinées dans la session
        sessionStorage.setItem('quizData', JSON.stringify(combinedData));

        // Ajoute les données combinées au store Redux
        dispatch(addUser(combinedData));

        // Marque le formulaire comme soumis
        setFormSubmitted(true);

        // Crée une instance de FormData
        let data = new FormData();
        data.append('habitation', combinedData.habitation);
        data.append('toiture', combinedData.toiture);
        data.append('chauffage', combinedData.chauffage);
        data.append('sous_sole', combinedData.chauffage);
        data.append('prenom', combinedData.prenom);
        data.append('nom', combinedData.nom);
        data.append('adresse', combinedData.adresse);
        data.append('tel', combinedData.tel);
        data.append('email', combinedData.email);
        data.append('agree', combinedData.agree);

        // Configuration de la requête
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://office.renovhabitatconseil.com/api/add',
            headers: { 
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        // Envoie les données combinées au serveur
        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                console.log('Données envoyées avec succès à la base de données.');
            } else {
                console.error('Erreur lors de l\'envoi des données à la base de données.');
            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi des données à la base de données :', error);
        }
    };

    const marginTop = { marginTop: '20px' };

    const colorText = { color : '#021aac'};

    const gap = { display: 'flex', flexWrap: 'wrap' };

    return (
        <>
            <div className='stepper'>
                {result ? (
                    formSubmitted ? (
                        <>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className="imag">
                                    {/* <h2 style={marginTop} className="text-center">Notre conseiller va vous contacter dans le plus bref délai</h2> */}
                                    <img src={congratulations} alt="photo" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={marginTop} className='container d-flex justify-content-between align-items-center'>
                                <div className='bloc-info'>
                                    <h3>Construisons ensemble votre projet !</h3>
                                    <p className='text-center'>Vous souhaitez participer à la transition énergétique ? Installer des équipements plus économiques et écologiques ?
                                    </p>
                                    <img className="img-5" src={image4} alt="photo" />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
                                    {/* <h3 class="header-form" style={colorText}>Mes coordoner</h3> */}
                                    <div className='champs'>
                                        <input
                                            type='text'
                                            placeholder='Prénom'
                                            {...register('prenom', { required: 'Prénom est requis' })}
                                        />
                                        {errors.prenom && <p className='error'>{errors.prenom.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <input
                                            type='text'
                                            placeholder='Nom'
                                            {...register('nom', { required: 'Nom est requis' })}
                                        />
                                        {errors.nom && <p className='error'>{errors.nom.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <input
                                            type='number'
                                            placeholder='Code postal'
                                            {...register('adresse', { required: 'Code postal est requis' })}
                                        />
                                        {errors.adresse && <p className='error'>{errors.adresse.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <input
                                            type='tel'
                                            placeholder='Numéro de téléphone'
                                            {...register('tel', { required: 'Numéro de téléphone est requis' , pattern: {
                                                value: /^[0-9]{10}$/, // Expression régulière pour un numéro de téléphone à 10 chiffres
                                                message: 'Veuillez entrer un numéro de téléphone valide'
                                            }})}
                                        />
                                        {errors.tel && <p className='error'>{errors.tel.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            {...register('email', { required: 'Email est requis' })}
                                        />
                                        {errors.email && <p className='error'>{errors.email.message}</p>}
                                    </div>
                                    <div className='champs'>
                                        <input
                                            className="check"
                                            type="checkbox"
                                            {...register('consentement', { required: 'Vous devez accepter la politique de confidentialité' })}
                                            defaultChecked 
                                        />
                                        <span>
                                            J'accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter et pour m'envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande et j'accepte la <a href="https://ma-douche.renovhabitatconseil.com/politique-de-confidentialite" target="_blank">politique de confidentialité</a>.
                                        </span>
                                        {errors.consentement && <p className='error'>{errors.consentement.message}</p>}
                                    </div>
                                    <button className="btn-send" type='submit'>Soumettre</button>
                                </form>
                            </div> 
                        </>
                    )
                ) : (
                    <div className='quiz'>
                        <div className="bloc">
                            <div className="bloc-1">
                                <h2>Parlez-nous de votre projet et obtenez un devis !</h2>
                                <p>En fonction de vos besoins, nos experts énergétiques vous proposeront les meilleures solutions.</p>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={image5} alt="photo" className='job'/>
                                </div>
                            </div>
                            <div className="bloc-2">
                                <h3 className='text-center title'>{data[index].question}</h3>
                                {/* <div className='index text-center'>{index + 1} sur {data.length} Questions</div> */}
                                <div className='choice-tablette d-flex justify-content-center align-items-center'>
                                {/* <ul className='choice'>
                                    {data[index].options.map((option, optionIndex) => {
                                        const optionValue = option.text;
                                        const isCorrect = optionValue === data[index][`option${data[index].ans}`];
                                        const isSelected = selectedAnswer === optionValue;
                                        let classNames = 'name';
                                        if (isSelected) classNames += ' selected';
                                        if (isCorrect && isSelected) classNames += ' correct';
                                        else if (!isCorrect && isSelected) classNames += ' wrong';
                                        return (
                                            <li
                                                key={optionIndex}
                                                onClick={() => checkAnswer(optionValue)}
                                                className={`d-flex justify-content-center align-items-center ${classNames}`}
                                            >
                                                <div className='d-flex flex-column'>
                                                    <div className='d-flex justify-content-center'>
                                                        <img src={option.image} alt={optionValue} className="option-image d-flex justify-content-center" />
                                                    </div>
                                                    <span className='text-center description'>{optionValue}</span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul> */}
                                    <ul className='choice'>
                                        {data[index].options.map((option, optionIndex) => {
                                            const optionValue = option.text;
                                            const isSelected = selectedAnswers[index]?.includes(optionValue); // Vérifie si l'option est sélectionnée

                                            return (
                                                <li
                                                    key={optionIndex}
                                                    onClick={() => checkAnswer(optionValue)}
                                                    className={`d-flex justify-content-center align-items-center name ${isSelected ? 'selected' : ''}`} // Ajoute la classe CSS "selected"
                                                >
                                                    <div className='d-flex flex-column'>
                                                        <div className='d-flex justify-content-center'>
                                                            <img src={option.image} alt={optionValue} className="option-image d-flex justify-content-center" />
                                                        </div>
                                                        <span className='text-center description'>{optionValue}</span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="suivant d-flex justify-content-center">
                                    <button className="next" onClick={next} disabled={!answerSelected}>Suivant</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Quiz;
